// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../graylog2-server/graylog2-web-interface/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3D0cISSr8Qd5n_glTebjTe {\n    font-size: 14px;\n}\n\n._3D0cISSr8Qd5n_glTebjTe div {\n    padding: 10px 15px;\n}\n\n._3D0cISSr8Qd5n_glTebjTe h4 {\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 1.1;\n    margin-bottom: 5px;\n}\n\n._3D0cISSr8Qd5n_glTebjTe p {\n    line-height: 1.3;\n    margin-bottom: 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/web/collectors/CollectorsPage.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[":local(.sidecarModal) {\n    font-size: 14px;\n}\n\n:local(.sidecarModal) div {\n    padding: 10px 15px;\n}\n\n:local(.sidecarModal) h4 {\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 1.1;\n    margin-bottom: 5px;\n}\n\n:local(.sidecarModal) p {\n    line-height: 1.3;\n    margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidecarModal": "_3D0cISSr8Qd5n_glTebjTe"
};
export default ___CSS_LOADER_EXPORT___;
